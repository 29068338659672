import React, { Component, Fragment } from 'react'
import { ABanner, ATabs, AButton, Go } from 'shared/components/common'
import { Container, Row, Col, ModalHeader, ModalBody, Modal, ModalFooter } from 'reactstrap'
import tufactura from 'img/vectores/tufactura.svg'
import PTXLogo from 'img/ptx_logo.png'
import { InvoiceProps, InvoiceState } from './types'
import { SearchService, ServiceInfo, BillingInfoList, BillingInfoSearch } from 'modules/invoice/components'
import { InvoiceService } from 'modules/invoice/services'
import { ABannerProps } from 'shared/components/common/ABanner/types'
import { Invoice } from 'modules/invoice/models'
import { ApiErrors } from 'core/services/ApiService/types'
import Alert from 'reactstrap/lib/Alert'
import { InvoiceBody, CFDIIngreso, ConceptoCFDI, BodyIngreso } from 'modules/invoice/services/InvoiceService/types'
import { Config } from 'core'
import moment from 'moment'
import { Link } from 'gatsby'
import { MailService } from 'services'
import { getBillingEmailString } from './BillingEmailTemplate'
import { InputFormsy } from 'shared/components/formsy'
import Formsy from 'formsy-react'
const statusIcons: IndexSignature = {
  progress: 'fa-tasks text-info',
  error: 'fa-exclamation-triangle text-warning',
  expired: 'fa-exclamation-circle text-danger'
}
const statusLegend: IndexSignature = {
  progress:
    'Ha ocurrido un error con la generación de su factura, favor de comunicarse con soporte allAbordo al correo facturacion@ptxpaqueteria.com',
  error:
    'Verifique que sus datos fiscales sean correctos, de persistir el problema, favor de comunicarse al correo facturacion@ptxpaqueteria.com',
  expired: 'Su factura ha sido evaluada como no elegible, favor de generarla nuevamente'
}

export class InvoiceLayout extends Component<InvoiceProps, InvoiceState> {
  form: any
  tabRef = ATabs.createRef()
  state: InvoiceState = {
    billingData: [],
    collapse: false,
    isEmailModalOpen: false,
    clientEmail: '',
    reservationData: null,
    taxRegimens: [],
    usosCFDI: []
  }

  componentDidMount() {
    if (this.props.invoice) {
      this.refreshBillingDataList()
      this.fetchTaxRegimens()
      this.fetchUsosCFDI()
      this.tabRef.selectTab(1)
    }
  }

  refreshBillingDataList = () => {
    const { user } = this.props
    if (user && user.customer) {
      this.setState({ searching: true, invoiceError: '' })
      InvoiceService.getBillingDataList(user.customer.id)
        .then(datas => {
          this.setState({ billingData: datas, searching: false })
        })
        .catch(this.handleError)
    }
  }

  fetchTaxRegimens = () => {
    InvoiceService.getTaxRegimens()
      .then(data => {
        this.setState({ taxRegimens: data })
      })
      .catch(err => {
        console.error('Error fetching tax regimens:', err)
      })
  }

  fetchUsosCFDI = () => {
    InvoiceService.getUsosCFDI()
      .then(data => {
        this.setState({ usosCFDI: data })
      })
      .catch(err => {
        console.error('Error fetching usos cfdi:', err)
      })
  }

  formaPago = (formaPago: String) => {
    switch (formaPago) {
      case 'cash':
      case 'oxxo':
      case 'deposit':
        return '01'
      case 'card':
        return '04'
      case 'debit':
        return '28'
      case 'check':
        return '02'
      case 'transfer':
      case 'codi':
        return '03'
      default:
        return '99'
    }
  }

  handleSearch = (code: string) => {
    const { setInvoiceData } = this.props
    this.setState({ searching: true, invoiceError: '' })
    const serviceID = code.charAt(0)
    let type
    switch (serviceID) {
      case 'G':
        type = 'parcel'
        break
      case 'B':
        type = 'boarding_pass'
        break
      case 'R':
        type = 'rental'
        break
      case 'P':
        type = 'guia_pp'
        break
      default:
        type = 'boarding_pass'
    }
    InvoiceService.getServiceDetail(code, type)
      .then(detail => {
        const tipo = detail.service
        if (tipo === 'Envío de paqueteria' || tipo === 'Boletos de autobuses' || tipo === 'Guias Prepagadas') {
          if (detail.extra_data && detail.extra_data.customer_billing_information) {
            detail.purchase_cbi = detail.extra_data.customer_billing_information
          }

          setInvoiceData({ ...detail })
          this.setState({ searching: false, reservationData: detail })

          this.refreshBillingDataList()
          this.fetchTaxRegimens()
          this.fetchUsosCFDI()
        } else {
          detail.invoice_status = 'reload'
          setInvoiceData({ ...detail })
          this.setState({ searching: false })
        }
      })
      .catch(this.handleError)
  }

  handleBillingInfoSearch = (model: any) => {
    this.setState({ searching: true, invoiceError: '' })
    InvoiceService.getBillingData(model.rfc, model.zipCode)
      .then(data => {
        this.setState({ searching: false, billingData: data })
      })
      .catch(this.handleError)
  }

  handleSelect = (
    data: any,
    name: string,
    zip_code: string,
    tax_regimen: string,
    email: string,
    cfdiUse: string,
    payment_condition: string
  ) => {
    const { invoice, user } = this.props
    if (invoice) {
      const { code, service_type } = invoice
      const { id } = data
      this.props.setInvoiceData({ email, service_type })
      this.makeInvoice(
        {
          email,
          code,
          name,
          zip_code,
          tax_regimen,
          payment_condition,
          type: service_type,
          customer_parcel_type: user?.customer?.parcel_type || '',
          cfdi_use: cfdiUse,
          billing_id: id
        },
        invoice
      )
    }
  }

  financial = (num: Number) => {
    return num.toFixed(4)
  }

  financialTotals = (num: Number) => {
    return num.toFixed(2)
  }

  makeInvoice = (body: InvoiceBody, reservation_data: any) => {
    this.setState({ searching: true, invoiceError: '' })
    InvoiceService.requestInvoice(body)
      .then(data => {
        const fechaString = moment.utc(new Date()).toString()
        this.updateBillingInfoCatalogueData(data.billing, { tax_regimen: data.tax_regimen, cfdi_use: data.cfdi_use })
        const json: CFDIIngreso = {
          Serie: data.serie,
          Version: '4.0',
          TranspInternac: 'No',
          TipoDeComprobante: 'I',
          Moneda: 'MXN',
          MetodoPago: this.getPaymentMethod(reservation_data),
          LugarExpedicion: data.parcel_info.terminal_zip_code.toString(),
          Fecha: fechaString.substring(0, fechaString.length - 5),
          Total: data.invoice.total_amount.toString(),
          Subtotal: this.financialTotals(data.invoice.amount),
          Descuento: '0',
          Emisor: {},
          Receptor: {},
          Conceptos: [],
          FormaPago: this.formaPago(data.invoice.payment_method),
          PaisOrigenDestino: 'MEX',
          Impuestos: {}
        }
        const arrConcepts = Array<ConceptoCFDI>()

        if (data.type === 'guia_pp') {
          let totalConceptos = 0

          data.parcel_info.details.forEach((detail: any, index: number) => {
            let detail_amount = parseFloat(this.financial(parseFloat(data.invoice.amount) / data.parcel_info.details.length))

            // Ajuste para el último concepto, por si hay errores de redondeo
            if (index === data.parcel_info.details.length - 1) {
              detail_amount = parseFloat(this.financial(parseFloat(this.financial(data.invoice.amount)) - totalConceptos))
            }

            const detail_iva = parseFloat(this.financial(detail_amount * data.parcel_info.branchIva))
            const detail_iva_withheld = parseFloat(this.financial(detail_amount * data.invoice.iva_withheld_percent))

            const impuestos: any = {
              Traslados: {
                Importe: this.financial(detail_iva),
                TipoFactor: 'Tasa',
                TasaOCuota: `${data.parcel_info.branchIva}0000`,
                Impuesto: '002',
                Base: this.financial(detail_amount)
              }
            }

            if (data.invoice.iva_withheld) {
              impuestos.Retenciones = {
                Importe: this.financial(detail_iva_withheld),
                TipoFactor: 'Tasa',
                TasaOCuota: `${data.invoice.iva_withheld_percent}0000`,
                Impuesto: '002',
                Base: this.financial(detail_amount)
              }
            }

            arrConcepts.push({
              Cantidad: '1',
              ClaveProdServ: this.getProductServiceKeyByType(data.type, reservation_data.is_freight ? 'freight' : 'courier'),
              Descripcion: this.getDescriptionByType(data.type, detail.code, reservation_data.is_freight ? 'freight' : 'courier'),
              Descuento: '0',
              Importe: this.financial(detail_amount),
              NoIdentificacion: detail.code,
              Unidad: 'E48',
              ValorUnitario: this.financial(detail_amount),
              Impuestos: impuestos
            })
            totalConceptos += detail_amount
          })
        } else if (data.type === 'parcel') {
          const packages = data.invoice.extra_data.packages
          let totalConcepts = 0

          // incluir concepto de EAD/RAD
          if (reservation_data.services_amount) {
            const services_iva = parseFloat(this.financial(reservation_data.services_amount * data.parcel_info.branchIva))
            arrConcepts.push({
              Cantidad: '1',
              ClaveProdServ: this.getProductServiceKeyByType(data.type, 'services'),
              Descripcion: this.getDescriptionByType(data.type, '', 'services'),
              Descuento: '0',
              Importe: this.financial(reservation_data.services_amount),
              NoIdentificacion: reservation_data.reservation_code,
              Unidad: 'E48',
              ValorUnitario: this.financial(reservation_data.services_amount),
              Impuestos: {
                Traslados: {
                  Importe: this.financial(services_iva),
                  TipoFactor: 'Tasa',
                  TasaOCuota: `${data.parcel_info.branchIva}0000`,
                  Impuesto: '002',
                  Base: this.financial(reservation_data.services_amount)
                }
              }
            })
            totalConcepts += reservation_data.services_amount
          }

          // incluir concepto de EMBALAJES
          if (reservation_data.extra_charges) {
            const packaging_iva = parseFloat(this.financial(reservation_data.extra_charges * data.parcel_info.branchIva))
            arrConcepts.push({
              Cantidad: '1',
              ClaveProdServ: this.getProductServiceKeyByType(data.type, 'packaging'),
              Descripcion: this.getDescriptionByType(data.type, '', 'packaging'),
              Descuento: '0',
              Importe: this.financial(reservation_data.extra_charges),
              NoIdentificacion: reservation_data.reservation_code,
              Unidad: 'E48',
              ValorUnitario: this.financial(reservation_data.extra_charges),
              Impuestos: {
                Traslados: {
                  Importe: this.financial(packaging_iva),
                  TipoFactor: 'Tasa',
                  TasaOCuota: `${data.parcel_info.branchIva}0000`,
                  Impuesto: '002',
                  Base: this.financial(reservation_data.extra_charges)
                }
              }
            })
            totalConcepts += reservation_data.extra_charges
          }

          // incluir concepto de SEGURO
          if (reservation_data.insurance_amount) {
            const insurance_iva = parseFloat(this.financial(reservation_data.insurance_amount * data.parcel_info.branchIva))
            arrConcepts.push({
              Cantidad: '1',
              ClaveProdServ: this.getProductServiceKeyByType(data.type, 'insurance'),
              Descripcion: this.getDescriptionByType(data.type, '', 'insurance'),
              Descuento: '0',
              Importe: this.financial(reservation_data.insurance_amount),
              NoIdentificacion: reservation_data.reservation_code,
              Unidad: 'E48',
              ValorUnitario: this.financial(reservation_data.insurance_amount),
              Impuestos: {
                Traslados: {
                  Importe: this.financial(insurance_iva),
                  TipoFactor: 'Tasa',
                  TasaOCuota: `${data.parcel_info.branchIva}0000`,
                  Impuesto: '002',
                  Base: this.financial(reservation_data.insurance_amount)
                }
              }
            })
            totalConcepts += reservation_data.insurance_amount
          }

          // POR CADA PAQUETE, agregar concepto PyM o CARGA
          packages.forEach((pkg: any, index: number) => {
            // Ajuste para el último concepto, por si hay errores de redondeo
            if (index === packages.length - 1) {
              pkg.t_freight = parseFloat(this.financial(parseFloat(this.financial(data.invoice.amount)) - totalConcepts))
            }

            const pkg_iva = parseFloat(this.financial(pkg.t_freight * data.parcel_info.branchIva))
            const pkg_iva_withheld = parseFloat(this.financial(pkg.t_freight * data.invoice.iva_withheld_percent))

            const impuestos: any = {
              Traslados: {
                Importe: this.financial(pkg_iva),
                TipoFactor: 'Tasa',
                TasaOCuota: `${data.parcel_info.branchIva}0000`,
                Impuesto: '002',
                Base: this.financial(pkg.t_freight)
              }
            }

            if (pkg.iva_withheld) {
              impuestos.Retenciones = {
                Importe: this.financial(pkg_iva_withheld),
                TipoFactor: 'Tasa',
                TasaOCuota: `${data.invoice.iva_withheld_percent}0000`,
                Impuesto: '002',
                Base: this.financial(pkg.t_freight)
              }
            }

            arrConcepts.push({
              Cantidad: '1',
              ClaveProdServ: this.getProductServiceKeyByType(data.type, pkg.is_freight ? 'freight' : 'courier'),
              Descripcion: this.getDescriptionByType(data.type, '', pkg.is_freight ? 'freight' : 'courier'),
              Descuento: '0',
              Importe: this.financial(pkg.t_freight),
              NoIdentificacion: pkg.package_code,
              Unidad: 'E48',
              ValorUnitario: this.financial(pkg.t_freight),
              Impuestos: impuestos
            })
            totalConcepts += parseFloat(this.financial(pkg.t_freight))
          })
        } else {
          const objConcepto = {
            Cantidad: '1',
            ClaveProdServ: this.getProductServiceKeyByType(data.type, reservation_data.is_freight ? 'freight' : 'courier'),
            Descripcion: this.getDescriptionByType(data.type, data.code, reservation_data.is_freight ? 'freight' : 'courier'),
            Descuento: '0',
            Importe: this.financial(data.invoice.amount),
            NoIdentificacion: data.code,
            Unidad: 'E48',
            ValorUnitario: this.financial(data.invoice.amount),
            Impuestos: {
              Traslados: {
                Importe: this.financial(data.invoice.iva),
                TipoFactor: 'Tasa',
                TasaOCuota: `${data.parcel_info.branchIva}0000`,
                Impuesto: '002',
                Base: this.financial(data.invoice.amount)
              }
            }
          }

          if (data.type === 'prepaid') {
            objConcepto.Descripcion = this.getPrepaidDescription(objConcepto.Descripcion, data.parcel_info.details)
          }
          arrConcepts.push(objConcepto)
        }

        json.Conceptos = arrConcepts

        json.Emisor = {
          Rfc: data.type === 'boarding_pass' ? Config.rfcBoardingPass : Config.rfcParcel,
          RegimenFiscal: '601',
          Nombre: data.type === 'boarding_pass' ? Config.rfcNameBoardingPass : Config.rfcNameParcel
        }
        json.Receptor = {
          Rfc: data.billing.rfc,
          UsoCFDI: data.cfdi_use,
          Nombre: data.name,
          DomicilioFiscalReceptor: data.zip_code,
          RegimenFiscalReceptor: data.tax_regimen.toString()
        }

        const trasladoObj: any = {
          TotalImpuestosTrasladados: this.financialTotals(data.invoice.iva),
          Traslado: {
            Base: this.financialTotals(data.invoice.amount),
            Importe: this.financialTotals(data.invoice.iva),
            TipoFactor: 'Tasa',
            TasaOCuota: `${data.parcel_info.branchIva}0000`,
            Impuesto: '002'
          }
        }

        let totalImpuestosRetenidos = 0
        if (data.invoice.iva_withheld) {
          totalImpuestosRetenidos = parseFloat(this.financialTotals(data.invoice.iva_withheld))
          trasladoObj.TotalImpuestosRetenidos = this.financialTotals(totalImpuestosRetenidos)
          trasladoObj.Retencion = {
            Impuesto: '002',
            Importe: trasladoObj.TotalImpuestosRetenidos
          }
        }

        const total = parseFloat(json.Total)
        const subtotal = parseFloat(json.Subtotal)
        let totalImpuestosTrasladados = parseFloat(trasladoObj.TotalImpuestosTrasladados)

        const totalCalculado = subtotal + totalImpuestosTrasladados - totalImpuestosRetenidos
        if (parseFloat(this.financialTotals(totalCalculado)) !== total) {
          if (data.invoice.iva_withheld) {
            totalImpuestosRetenidos = parseFloat(this.financialTotals(subtotal + totalImpuestosTrasladados - total))
            trasladoObj.TotalImpuestosRetenidos = this.financialTotals(totalImpuestosRetenidos)
            trasladoObj.Retencion.Importe = trasladoObj.TotalImpuestosRetenidos
          } else {
            totalImpuestosTrasladados = parseFloat(this.financialTotals(total - subtotal))
            trasladoObj.TotalImpuestosTrasladados = this.financialTotals(totalImpuestosTrasladados)
            trasladoObj.Traslado.Base = this.financialTotals(subtotal)
            trasladoObj.Traslado.Importe = trasladoObj.TotalImpuestosTrasladados
          }
        }

        json.Impuestos = trasladoObj

        const bodyIngreso: BodyIngreso = {
          cfdi_use: data.cfdi_use,
          cfdi: json,
          code: data.code,
          parcel_id: data.invoice.id,
          invoice: data.invoice,
          billing: data.billing,
          email: data.email,
          type: data.type
        }
        return bodyIngreso
      })
      .then(data => {
        InvoiceService.registerTimbox(data).then(result => {
          const d = result
          if (d.error) {
            const parser = new DOMParser()
            const xml = parser.parseFromString(d.error, 'application/xml')
            const errorNode = xml.getElementsByTagName('faultstring')[0]
            const errorMessage = errorNode.textContent
            this.props.setInvoiceData({ invoice_status: 'error', errorMessage: errorMessage ?? undefined })
            this.setState({ searching: false })
          } else {
            this.toggle()
            this.SendBillingLinkPdfToEmail(data.code, '', data.email)
            this.props.setInvoiceData({ invoice_status: 'done' })
            this.setState({ searching: false })
          }
        })
      })
      .catch(this.handleError)
  }

  getPaymentMethod = (reservation_data: any) => {
    const methods = {
      ppd: 'PPD',
      pue: 'PUE'
    }

    switch (reservation_data.payment_condition) {
      case 'credit':
        return methods.ppd
      case 'cash':
        return reservation_data.paid ? methods.pue : methods.ppd
      default:
        return ''
    }
  }

  updateBillingInfoCatalogueData = (billingObj: any, newData: any) => {
    if (newData.tax_regimen) {
      billingObj.c_RegimenFiscal_id = this.getArrayIdFromAttribute(this.state.taxRegimens, 'c_RegimenFiscal', newData.tax_regimen)
    }
    if (newData.cfdi_use) {
      billingObj.c_UsoCFDI_id = this.getArrayIdFromAttribute(this.state.usosCFDI, 'c_UsoCFDI', newData.cfdi_use)
    }
  }

  getArrayIdFromAttribute = (array: any, attribute: any, val: any) => {
    if (val) {
      const found = array.find((a: any) => a[attribute] === val)
      if (found) {
        return found.id
      }
      return null
    }
    return null
  }

  getProductServiceKeyByType = (type: string, conceptType: any) => {
    const data: any = {
      boarding_pass: '78111802',
      prepaid: '78111802',
      parcel: {
        freight: '78101800',
        courier: '78102200',
        packaging: '78121502',
        services: '78102200',
        insurance: '84131500'
      },
      guia_pp: {
        freight: '78101800',
        courier: '78102200'
      },
      rental: '78111803'
    }

    const value = data[type]
    if (typeof value === 'object') {
      return value[conceptType] || ''
    }
    return value || ''
  }

  getDescriptionByType = (type: string, code: string, conceptType: any) => {
    const data: any = {
      prepaid: 'PAQUETE DE BOLETOS PREPAGO',
      boarding_pass: `SERVICIO DE VIAJE ${code}`,
      parcel: {
        freight: `TRANSPORTE DE CARGA POR CARRETERA`,
        courier: `PAQUETERIA Y MENSAJERIA`,
        packaging: `SERVICIOS DE EMBALAJE`,
        services: `ENTREGA Y RECOLECCION A DOMICILIO`,
        insurance: `SEGURO`
      },
      guia_pp: {
        freight: `TRANSPORTE DE CARGA POR CARRETERA`,
        courier: `PAQUETERIA Y MENSAJERIA`
      },
      rental: `RENTA DE UNIDAD CODIGO ${code}`
    }

    const value = data[type]

    if (typeof value === 'object') {
      return value[conceptType] || ''
    }
    return value || ``
  }

  getPrepaidDescription = (description: string, details: any) => {
    const maxChars = 997
    let result = description
    let remainingChars = maxChars - description.length
    let ellipsis = ''

    for (const detail of details) {
      const detailCode = detail.code
      const detailLength = detailCode.length + 2

      if (result.endsWith('PREPAGO') && remainingChars >= detailLength) {
        result += `: ${detailCode}`
        remainingChars -= detailLength
      } else if (remainingChars >= detailLength + 1) {
        result += `, ${detailCode}`
        remainingChars -= detailLength + 1
      } else {
        ellipsis = ', ...'
        break
      }
    }
    return result + ellipsis
  }

  clearInvoice = () => {
    const { removeInvoiceData } = this.props
    removeInvoiceData()
  }

  handleError = (err: any) => {
    const newState: any = { searching: false }
    newState.invoiceError = this.getErrorMessage(err)
    this.setState(newState)
  }

  getErrorMessage = (err: any) => {
    if (err.status && err.status === ApiErrors.HANDLED_ERROR) {
      return err.message
    }
    if (err.error) {
      const devMsg = err.error.devMessage
      if (devMsg && devMsg === 'Service: not found') {
        return 'No se encontró el servicio especificado. Intente con otro código.'
      }
    }
    return 'Error al realizar la búsqueda. Intente de nuevo más tarde.'
  }

  handleNewData = () => {
    this.refreshBillingDataList()
  }
  toggle = () => {
    this.setState(state => ({ collapse: !state.collapse }))
  }
  reload = () => {
    this.props.setInvoiceData({ invoice_status: 'done' })
  }

  SendBillingLinkPdfToEmail = (code: string, clientName: string, email: string) => {
    this.setState({ ...this.state, isEmailModalOpen: false })
    MailService.sendEmail({
      subject: 'PTX Paquetería y mensajería | Factura Electrónica',
      html_content: getBillingEmailString(document.location.origin, code, clientName),
      send_to: email
    })
  }

  handleSendEmail(code: string, clientName: string) {
    if (this.props.invoice && this.props.invoice.customer.email) {
      return this.SendBillingLinkPdfToEmail(code, clientName, this.props.invoice.customer.email)
    }
    this.setState({ code, isEmailModalOpen: true, client: clientName })
  }

  getEmailForm = (f: any) => {
    if (f) {
      this.form = f
      const { clientEmail = '' } = this.state
      this.form.reset({ email: clientEmail })
    }
  }

  onEmailSubmit = (model: any) => {
    this.setState({ ...this.state, isEmailModalOpen: false, clientEmail: model.email })
    const { code = '', client = '' } = this.state
    const { email } = model as { email: string }
    this.SendBillingLinkPdfToEmail(code, client, email)
  }

  onValidFormEmail = () => {
    this.setState({ canSubmitEmail: true })
  }

  onInvalidFormEmail = () => {
    this.setState({ canSubmitEmail: false })
  }

  render() {
    const { searching, billingData, invoiceError, reservationData } = this.state
    const { invoice, user } = this.props

    const headerContent: ABannerProps = {
      title: 'Tu factura',
      titleClassName: 'big-white',
      content: <img src={tufactura} />,
      titleSize: 10
    }
    let errorMessage
    if (invoiceError) {
      errorMessage = (
        <Alert color="danger" className="d-inline-block">
          {invoiceError}
        </Alert>
      )
    }

    let BodyContent = (
      <Fragment>
        <Row>
          <Col sm={12}>
            <p>Realiza la búsqueda de tu servicio para continuar con el proceso de facturación.</p>
            <br />
          </Col>
        </Row>
        {errorMessage}
        <SearchService onConfirm={this.handleSearch} disabled={searching} />
      </Fragment>
    )

    if (invoice) {
      headerContent.title = `${invoice.service}\n${invoice.code}`
      headerContent.content = <ServiceInfo {...invoice} />
      headerContent.titleClassName = 'big-white-op'
      headerContent.titleSize = 4
      if (invoice.invoice_status === 'done' && invoice.media_document_pdf_name && invoice.media_document_xml_name) {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <h4 className="big-blue">Tu factura ha sido enviada con éxito al correo:</h4>
              <h2 className="big-gray">{invoice.email}</h2>
              <br />
              <div className="btn-group">
                <a href={`$ Config.cfdi}/${invoice.media_document_pdf_name}`} target="blank">
                  <AButton variant="pink">Descargar pdf</AButton>
                </a>
                <a href={`$ Config.cfdi}/${invoice.media_document_xml_name}`} target="blank">
                  <AButton variant="pink">Descargar XML</AButton>
                </a>
              </div>
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Generar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'done') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Link to={`/imprimirfactura/?code=${this.props.invoice ? this.props.invoice.code : ''}`}>Imprimir factura</Link>{' '}
              <div className="mb-3" />
              <AButton
                variant="pink"
                onClick={() =>
                  this.handleSendEmail(
                    this.props.invoice ? this.props.invoice.code : '',
                    this.props.invoice ? `${this.props.invoice.customer.first_name} ${this.props.invoice.customer.last_name}` : ''
                  )
                }
              >
                Enviar enlace de factura a correo
              </AButton>{' '}
              <br /> <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'reload') {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center">
              <br />
              <br />
              <br />
              <h4 className="big-blue">Servicio no disponible para facturación</h4>
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Generar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'expired') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'progress') {
        BodyContent = (
          <Row>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Estatus:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Fragment>
                <br />
                <br />
                <AButton variant="pink" onClick={this.clearInvoice}>
                  Volver a solicitar factura
                </AButton>
              </Fragment>
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'error') {
        BodyContent = (
          <Row>
            <Col sm={12} className="text-center mt-5">
              <h5 className="big-gray">Status:</h5>
              <h1 className="big-pink">{Invoice.statusTexts[invoice.invoice_status]}</h1>
              <h4 className="big-blue">{invoice.errorMessage}</h4>
              <i className={`fa fa-5x mt-3 ${statusIcons[invoice.invoice_status]}`} />
              <br />
              <br />
              <Fragment>
                <br />
                <br />
                <AButton variant="pink" onClick={this.clearInvoice}>
                  Volver a solicitar factura
                </AButton>
              </Fragment>
            </Col>
            <Col sm={12} className="bluep">
              {statusLegend[invoice.invoice_status]}
            </Col>
          </Row>
        )
      } else if (invoice.invoice_status === 'not_required') {
        BodyContent = (
          <Row className="pad">
            <Col sm={2} className="m-auto text-center">
              <i className="fa fa-8x mt-3  mb-sm-3 fa-file-invoice-dollar m-auto text-center" style={{ color: '#27387e' }} />
            </Col>
            <Col sm={10}>
              <h5 className="big-blue-mon">
                No se requirió factura al momento de documentar, por lo cual no es posible generar su factura en sitio web, para solicitarla
                envíe un correo al siguiente e-mail: <a href="mailto:facturacion@ptxpaqueteria.com"> facturacion@ptxpaqueteria.com </a>
              </h5>
              <p className="mb-0">Con los siguientes datos:</p>
              <ul className="big-blue-mon">
                <li>Código de rastreo de la carta porte</li>
                <li> Razón social</li>
                <li> RFC</li>
                <li> Código postal</li>
                <li> Régimen fiscal</li>
                <li> Uso de CFDI</li>
                <li> Correo electronico</li>
              </ul>
              <div className="text-center">
                <AButton variant="med" onClick={this.clearInvoice}>
                  Buscar otra factura
                </AButton>
              </div>
            </Col>
          </Row>
        )
      } else {
        const billingList = billingData.map(d => {
          return {
            id: d.id,
            name: d.name,
            person: d.legal_person,
            rfc: d.rfc,
            address: d.address,
            zip_code: d.zip_code,
            c_RegimenFiscal_id: d.c_RegimenFiscal_id,
            c_UsoCFDI_id: d.c_UsoCFDI_id
          }
        })

        const ListComponent = (
          <Fragment>
            <BillingInfoList
              data={billingList}
              user={user}
              onAdd={this.handleNewData}
              onSelectData={this.handleSelect}
              loading={searching}
              reservationData={reservationData}
            />
            <br />
            <br />
            <div className="text-center">
              <AButton variant="med" onClick={this.clearInvoice}>
                Buscar otra factura
              </AButton>
            </div>
          </Fragment>
        )

        if (!user) {
          BodyContent = (
            <Fragment>
              <Row>
                <Col sm={12}>
                  <p>
                    Realiza la búsqueda de tus datos de facturación, en caso de que no tengas ninguno registrado te recomendamos
                    <Go to="iniciarsesion"> iniciar sesión</Go>.
                  </p>
                  <br />
                </Col>
              </Row>
              {errorMessage}
              <BillingInfoSearch onConfirm={this.handleBillingInfoSearch} disabled={searching} />
              {ListComponent}
            </Fragment>
          )
        } else {
          BodyContent = ListComponent
        }
      }
    }

    return (
      <React.Fragment>
        <ABanner {...headerContent} />
        <Container className="pad">
          <Modal isOpen={this.state.collapse} size="lg" toggle={this.toggle}>
            <ModalHeader toggle={this.toggle}>
              <Row className="text-center">
                <Col sm={12} className="text-center">
                  <img style={{ width: '200px' }} src={PTXLogo} className="img-responsive" />
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col>
                  <h2 className="text-center">Factura realizada correctamente</h2>
                </Col>
              </Row>
              <Row>
                <Col>
                  <div className="text-center">
                    <button
                      type="submit"
                      onClick={() => {
                        this.toggle()
                      }}
                      className="m-btn-med-rosa "
                    >
                      OK
                    </button>
                  </div>
                </Col>
              </Row>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.isEmailModalOpen} size="lg" toggle={this.toggle}>
            <Formsy
              ref={this.getEmailForm}
              onValidSubmit={this.onEmailSubmit}
              onValid={this.onValidFormEmail}
              onInvalid={this.onInvalidFormEmail}
            >
              <ModalHeader toggle={this.toggle}>
                <label className="bluep h6">
                  <i className="fas fa-address-book" style={{ paddingRight: 10 }} /> Datos de facturación
                </label>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Col sm={12}>
                      <InputFormsy
                        name="email"
                        label="Correo electrónico:"
                        inputProps={{ placeholder: 'Escribe tu correo electrónico' }}
                        validations={{ isEmail: true }}
                        validationErrors={{ isEmail: 'El texto introducido no es un correo válido' }}
                        required
                      />
                    </Col>
                  </Row>
                </Container>
              </ModalBody>
              <ModalFooter>
                <AButton type="submit" variant="pink" disabled={!this.state.canSubmitEmail}>
                  Generar
                </AButton>
                <AButton type="button" variant="gray" onClick={() => this.setState({ isEmailModalOpen: false })}>
                  Cancelar
                </AButton>
              </ModalFooter>
            </Formsy>
          </Modal>
          {BodyContent}
        </Container>
      </React.Fragment>
    )
  }
}

export default InvoiceLayout
